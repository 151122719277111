@import '@ng-select/ng-select/themes/default.theme.css';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/forms';

html,
body {
    height: 100%;
}

#container {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -50px;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}

.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}

.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

div.wrapper,
div.content-wrapper,
div.sidebar {
    min-height: 100vh;
}

table.table {
    * {
        font-size: 14px !important;
    }

    th,
    td {
        padding: 16px;
        vertical-align: middle;
    }
}

table-card-footer * {
    font-size: 14px;
}

/* ng-select */
.ng-select .ng-select-container {
    min-height: 38px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
}

.input-group-append .ng-select .ng-select-container {
    border-radius: 0 4px 4px 0 !important;
}

ng-select.form-control {
    padding: initial !important;
}

.form-control .ng-select-container {
    position: initial !important;
    border: 0px;
    background: transparent !important;
    min-height: initial !important;
    height: inherit !important;
}

.form-control ng-value-container {
    padding-top: 0px !important;
}

bs-tooltip-container {
    transition-duration: 0ms;
}

.freeze-ui {
    position: fixed !important;
}

//Typography
.text-small {
    font-size: 0.85rem;
}

.text-extrasmall {
    font-size: 0.8rem;
}

.text-regular {
    font-weight: 400 !important;
}

.text-big {
    font-size: 2rem;
}

//Grid
.col-20 {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
}

@media (min-width: 993px) {
    .col-20 {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

//Color & Border
.color-primary {
    color: #f17506 !important;
}

.color-secondary {
    color: #1a273d;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #1a273d !important;
}

.color-blue {
    color: #27479c;
}

.bg-primary {
    background-color: #f17506 !important;
}

.bg-secondary {
    background-color: #1a273d !important;
}

.border-orange {
    border: 1px solid #f17506;
}

.border-secondary {
    border: 1px solid #1a273d;
}

.border-bottom {
    border-bottom: 1px solid #1a273d !important;
}

.border-radius {
    border-radius: 5px;
}

.border-radius-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.border-radius-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

//Button
.btn {
    text-transform: uppercase;
    padding: 0.5rem 1.75rem !important;
}

.btn-primary {
    color: #fff;
    background-color: #f17506 !important;
    border-color: #f17506 !important;
    border-style: solid;
}

.btn-white {
    background-color: white;
    border-color: #f17506;
    color: #f17506;
    border-style: solid;
}

a.btn-white {
    color: #f17506 !important;
    font-weight: 600;
}

.btn-primary:hover,
button.bg-primary:hover,
button.bg-primary:focus,
.btn-white:hover,
a.btn-white:hover,
a.btn-white:focus {
    background-color: #1a273d !important;
    border-color: #1a273d !important;
    color: #fff !important;
}

.btn-md {
    padding: 0.125rem 1rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
}

.btn-sm {
    border-width: 2px !important;
    min-width: 33px;
}

//Login - Register
.login-card-body,
.register-card-body {
    background-color: #fff;
    border-top: 0;
    color: #666;
    padding: 20px;
    border: 1px solid #707070;
    border-radius: 5px;
}

//Navbar
.navbar-CoopAttiva {
    width: 100%;
    justify-content: space-around;
}

.cursor-pointer {
    cursor: pointer;
}

.navbar-nav.navbar-CoopAttiva a.nav-link span {
    color: #1a273d;
}

.navbar-nav.navbar-CoopAttiva a.nav-link:hover span,
.navbar-nav.navbar-CoopAttiva .nav-item.active,
.navbar-nav.navbar-CoopAttiva .nav-item.active > .nav-link span {
    color: #f17506 !important;
}

@media (max-width: 991px) {
    #navbarCA {
        padding: 1rem;
    }
}

.dropdown-item {
    cursor: pointer;
}

//Card
.card-DocumentList {
    height: 225px;
}

.card-DocumentList .card-body {
    padding: 0.75rem;
}

//Pagination
.page-item {
    margin-left: 1rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #f17506 !important;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    color: #707070 !important;
    border: 1px solid #707070 !important;
}

.page-item .page-link {
    color: #f17506;
}

.page-item .page-link:hover,
.page-item.active .page-link {
    background-color: #f17506 !important;
    color: white !important;
}

.pagination {
    justify-content: center;
}

//Table
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #1a273d !important;
    border-top: none !important;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #1a273d !important;
    border-bottom: 1px solid #1a273d !important;
}

.shadow-default {
    box-shadow: 3px 3px 6px #00000087;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -6px !important;
}

.ng2-tag-input.ng2-tag-input--focused {
    border-bottom: 2px solid #f17506 !important;
}

tag {
    background-color: #e0e0e0 !important;
    font-size: 12px !important;
    align-content: center !important;
    margin: 0.1rem 0 0.1rem 1rem !important;
}

.autocomplete-container .input-container input {
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #ced4da !important;
    box-shadow: none;
    outline: none;
    background-color: #fff;
    color: #000000de;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
    border-radius: 5px;
}

.autocomplete-container {
    box-shadow: none !important;
    position: relative;
    overflow: visible;
    height: 40px;
    border-radius: 5px;
}

.autocomplete-container .input-container .x {
    right: 50px !important;
}

.clickable {
    &:hover {
        cursor: pointer;
    }
}

.divider {
    border-bottom: 1px solid black;
}

// Hide Edge password-reveal icon
::-ms-reveal {
    display: none;
}

//Stepper
.mat-step-icon {
    height: 60px !important;
    width: 60px !important;
}

.ng-invalid {
    @extend .is-invalid;
}

.animate-spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
